import React from 'react'
import CountUp from 'react-countup'
import { motion } from 'framer-motion'
import { container, item } from './Section1'
// import MarketBrads from '../../assets/images/Market-brands.png'
import ArrowTop from '../../assets/images/arrow-top-right.svg'
import HotelClassLogo from '../../assets/images/hotel_class.svg'
import HotelClassLogo2 from '../../assets/images/hotel_class_gray.svg'
import MessageLogo from '../../assets/images/message.svg'
import PriceChange from '../../assets/images/price_change.svg'
import FinanceGif from '../../assets/images/total deduction.gif'
import useGif from '../../hooks/useGif'
const Section2 = () => {
  const gif = useGif(FinanceGif)

  return (
    <>
      <div className="s2-container">
        <div className="f-width m-auto text-center">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="h-card1 card-height">
                <div className="card-head-text d-flex">
                  <img src={MessageLogo} alt="Dispute_manager_icon" />
                  <p className='d-mgr'>DISPUTE MANAGER</p>
                </div>
                
                <div className="row card-block1 m-1 mb-4">
                  <div className="col my-1">
                    <p className="card-sub-text1">Dispute won amount</p>
                    <div className="row mt-1">
                      <p className="col-4 card-sub-text2">
                        $<CountUp delay={0.2} start={0} end={84000} duration={6} />
                      </p>
                      <div className="col-8 align-self-center text-end">
                        <img className='atop-size' src={ArrowTop} alt="Arrow_Top" />
                        <font className="ps-2 fs-14px fw-500 text-secondary">
                          <font className="color-green fw-600">10.04%</font> this week
                        </font>
                      </div>
                    </div>
                    <p className="card-sub-text3 pb-1">
                      Disputed orders: <CountUp delay={0.2} start={0} end={8700} duration={6} />{' '}
                    </p>
                  </div>
                </div>

                <div className="row card-block2 m-1">
                  <div className="col my-1">
                    <p className="card-sub-text1">Pending dispute</p>
                    <div className="row mt-1">
                      <p className="col-4 card-sub-text2">
                        <CountUp delay={0.2} start={0} end={5600} duration={6} />
                      </p>
                      <div className='col-8 align-self-center text-end animate__animated animate__bounceIn' style={{animationDelay:'0.5s'}}>
                        <font className="cta-btn color-brand">Dispute</font>
                      </div>
                    </div>
                    <p className="card-sub-text3 pb-1">
                      Disputed amount: $<CountUp delay={0.2} start={0} end={51280} duration={6} decimal="," />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="h-card1">
                <div className="card-head-text d-flex">
                  <img src={HotelClassLogo} className="img-white" alt="Dispute_manager_icon" />
                  <p>REVIEWS AND RATINGS</p>
                </div>
                <div className="row card-block1 m-1 mb-2">
                  <div className="col">
                    <p className="card-sub-text1">Unanswered reviews</p>
                    <div className="row mt-1">
                      <p className="col-4 card-sub-text2">
                        <CountUp delay={0.2} start={0} end={6433} duration={6} />
                      </p>
                      <div className='col-8 align-self-center text-end pb-2 animate__animated animate__bounceIn' style={{animationDelay:'0.5s'}}>
                        <font className="cta-btn color-brand">Auto reply</font>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-card1 mt-2">
                <div className="card-head-text d-flex">
                  <img src={HotelClassLogo2} alt="Dispute_manager_icon" />
                  <p className="text-secondary">MARKETPLACE MANAGEMENT</p>
                </div>
                <div className="row card-block1 m-1 mb-2">
                  <div className="col">
                    <div className="row">
                      <div className="col p-2">
                      <motion.div variants={container} whileInView="visible" initial="hidden" className="brands-btn3 animate__animated animate__slideInLeft animate__slow">
                        <motion.div variants={item}><font style={{color:'#ff3818'}}>DoorDash</font></motion.div>
                        <motion.div variants={item}>Uber <font style={{color:'#1dc36b'}}>Eats</font></motion.div>
                        <motion.div variants={item}><font style={{color:'#ff8313'}}>GrubHub</font></motion.div>
                      </motion.div>
                      <motion.div variants={container} whileInView="visible" initial="hidden" className="brands-btn3 animate__animated animate__slideInLeft animate__slow">
                        <motion.div variants={item}><font style={{color:'#1bb577'}}>ez</font><font style={{color:'#123f3e'}}>Cater</font></motion.div>
                        <motion.div variants={item}><font style={{color:'#4889f5'}}>G</font><font style={{color:'#eb4a3d'}}>o</font><font style={{color:'#fabf1d'}}>o</font><font style={{color:'#4889f5'}}>g</font><font style={{color:'#3cab58'}}>l</font><font style={{color:'#eb4a3d'}}>e</font></motion.div>
                        <motion.div variants={item}><font style={{color:'#ff2725'}}>Yelp</font></motion.div>
                      </motion.div>
                        {/* <img className="market-brand" src={MarketBrads} alt="Market Brands" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="h-card1 card-height">
                <div className="card-head-text d-flex">
                  <img src={PriceChange} className="img-white" alt="Price_Change_icon" />
                  <p>FINANCE</p>
                </div>
                <div className="d-grid card-block1 m-1 mb-2 graph-img-card">
                  <div className="position-relative m-auto">
                    <img loading="lazy" className="graph_img" src={gif} alt="Finance Graph" />
                    <div style={{ top: '55px', right: '-25px' }} className="position-absolute bg-white d-flex shadow-lg align-items-center justify-content-center rounded-3 p-2">
                      <div style={{ background: '#3B6BF3', width: '2px' }} className="me-2 p-2 rounded-pill" />
                      <small style={{ fontSize: '11px' }}>Error charges $7,307</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section2
