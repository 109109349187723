import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/VooshLogo.webp'
import Footlogo3 from '../assets/images/footer_3.png'
import Footlogo2 from '../assets/images/footer_captera.png'
import Mail from '../assets/images/mail.svg'
import Phone from '../assets/images/phone_supply.svg'
import Footlogo1 from '../assets/images/y_combinator_logo.png'
import { logGoogleEvent } from '../firebase'

const Footer = () => {
  return (
    <div className="footer-container" style={{zIndex:'99'}}>
      <div className="container f-width m-auto">
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <Link to={'/'}>
              <img className="footer-logo" src={Logo} alt="Voosh footer logo" />
            </Link>
            <p className="foot-text mt-3">
              We're Here to Help You
              <br /> Maximize Your Potential
            </p>
            <a href="mailto: bilal@voosh.ai" onClick={() => {
                    logGoogleEvent("Mail button click", {
                      name: "Mail button click",
                    });
                  }} >
              <p className="foot-text">
                <img className="me-2" src={Mail} alt="Voosh email" />
                Mail Us
              </p>
            </a>
            <a href="tel: +1(201)7620216" onClick={() => {
                    logGoogleEvent("Contact us phone click", {
                      name: "Contact us phone click",
                    });
                  }}>
              <p className="foot-text">
                <img className="me-2" src={Phone} alt="Voosh phone" />
                Contact Us
              </p>
            </a>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 mt-100">
            <p className="foot-h-text">Address</p>
            <p className="foot-text">2261, Market Street #4509, San Francisco, CA 94114</p>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 mt-100">
            <Link to={'/product-features'}>
              <p className="foot-h-text">Product Features</p>
            </Link>
            <Link to={'/dispute-manager'}>
              <p className="foot-text">Dispute Manager</p>
            </Link>
            <Link to={'/promotion-manager'}>
              <p className="foot-text">Promotions Manager</p>
            </Link>
            <Link to={'/downtime-controller'}>
              <p className="foot-text">Downtime Controller</p>
            </Link>
            <Link to={'/review-rating'}>
              <p className="foot-text">Reviews and Ratings</p>
            </Link>
            <Link to={'/finance'}>
              <p className="foot-text">Finance and Reconciliation</p>
            </Link>
            <Link to={'/analytics-insights'}>
              <p className="foot-text">Analytics and Insights</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 mt-100">
            <p className="foot-h-text">Company</p>
            <Link to={'/about-us'}>
              <p className="foot-text">About Us</p>
            </Link>
            <Link to={'/success-stories'}>
              <p className="foot-text">Success Stories</p>
            </Link>
            <Link to={'/blogs'}>
              <p className="foot-text">Blogs</p>
            </Link>
            <Link to={'/privacy-policy'}>
              <p className="foot-text">Privacy Policy</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 mt-100 foot-bramds-mv">
            <p className="foot-h-text">Backed By</p>
            <a href="https://www.ycombinator.com/companies/voosh">
              <img className="" src={Footlogo1} alt="y_combinator_logo" />
            </a>
            <a href="https://www.capterra.com/p/10012308/Voosh/">
              <img className="mt-3" src={Footlogo2} alt="footer_captera" />
            </a>
            <a href="https://www.g2.com/products/voosh/reviews">
              <img className="mt-3" src={Footlogo3} alt="read our reviews" />
            </a>
          </div>
        </div>

        <div className="foot-line mt-5"></div>

        <div className="row py-3">
          <div className="col-lg-4 col-sm-6">
            <div className="d-flex g-36 align-items-center">
              <a aria-label="YouTube Channel" href="https://www.youtube.com/@vooshusa" target='_blank' rel="noreferrer">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="32"  height="32"  viewBox="0 0 24 24"  fill="none"  stroke="#ffff"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" /><path d="M10 9l5 3l-5 3z" /></svg>
              </a>
              <a aria-label="LinkedIn" href="https://www.linkedin.com/company/vooshfoods/" target='_blank' rel="noreferrer">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="32"  height="32"  viewBox="0 0 24 24"  fill="none"  stroke="#ffff"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M8 11l0 5" /><path d="M8 8l0 .01" /><path d="M12 16l0 -5" /><path d="M16 16v-3a2 2 0 0 0 -4 0" /></svg>
              </a>
              <a aria-label="Instagram" href="https://www.instagram.com/voosh.ai" target='_blank' rel="noreferrer">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="32"  height="32"  viewBox="0 0 24 24"  fill="none"  stroke="#ffff"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
              </a>
              <a aria-label="Twitter Link" href="https://twitter.com/vooshusa"  target='_blank' rel="noreferrer">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="28"  height="28"  viewBox="0 0 24 24"  fill="none"  stroke="#ffff"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-12">
            <div className="d-flex f-bottom-text justify-content-evenly align-items-center">
              {/* <p>Support</p>
              <p><Link to={'/privacy-policy'}>Privacy Policy</Link></p>
              <p>Terms and Conditions</p> */}
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-12">
            <p className="f-bottom-text text-end text-c-mv">© {new Date().getFullYear()} Voosh food technologies, All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
