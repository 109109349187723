import React from "react";
import Sec1img from "../../assets/images/Estimated vs Actual Payouts.png";
import Sec2img from "../../assets/images/POS vs Off-Premise Reconciliation 2.webp";
import Sec3img from "../../assets/images/Detailed Transaction Insights 3.webp"

const Section2Fin = () => {
  return (
    <div className="s2-container-rm">
      <div className="row f-width m-auto align-items-center">
        <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
          <img
            className="img-fluid"
            src={Sec1img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#9061F9" }}>
              01
            </p>
            <p className="t2">Estimated vs. Actual Payouts</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#9061F9" }}
          ></div>
          <p className="s2-dm-bdy-text">
            <strong>See Exactly What You Should Get Paid</strong>: Compare your expected payouts with the actual amounts deposited. Instantly identify and resolve discrepancies, whether they're due to fees, commissions, or marketing adjustments, so you can ensure that every dollar is accounted for.
          </p>
        </div>
      </div>
      <div className="row f-width m-auto align-items-center mt-160">
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#9061F9" }}>
              02
            </p>
            <p className="t2">POS vs. Off-Premise Reconciliation</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#9061F9" }}
          ></div>
          <p className="s2-dm-bdy-text">
            <strong>Align Your Sales Data Across All Channels</strong>: Reconcile your POS data with off-premise orders from platforms like UberEats and DoorDash. This feature ensures that your sales data matches up, giving you a unified view of your finances and preventing any revenue loss.
          </p>
        </div>
        <div className="col-lg-7 col-sm-12">
          <img
            className="img-fluid"
            src={Sec2img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
      </div>
      <div className="row f-width m-auto align-items-center mt-160">
        <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
          <img
            className="img-fluid"
            src={Sec3img}
            alt="Voosh Dispute Manager feature3"
          />
        </div>
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#9061F9" }}>
              03
            </p>
            <p className="t2">Detailed Transaction Insights</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#9061F9" }}
          ></div>
          <p className="s2-dm-bdy-text">
            <strong>Get to the Bottom of Every Transaction</strong>: Our dashboard provides a detailed breakdown of every transaction, helping you understand exactly where your money is going. From marketing fees to payment adjustments, you'll have the clarity you need to manage your finances confidently.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2Fin;
